export default function ({ $nuxt }) {
  $nuxt.$on("ready", () => {
    $nuxt.$nextTick(() => {
      const { $axios } = this;
      $axios.onRequest((config) => {
        config.headers["Access-Control-Allow-Origin"] = "*";
      });
    });
  });
}
